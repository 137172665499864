import { GlobalActionTypes } from '../actions/global.action.types';
import { RoutingPlansAction } from '../staticData';

const initialState: string[] = [];

export const routingPlansReducer = (
  state: string[],
  { type, payload }: GlobalActionTypes,
) => {
  if (type === RoutingPlansAction.reset) {
    return initialState;
  }

  if (type === RoutingPlansAction.set) {
    return payload;
  }

  return state;
};

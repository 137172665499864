const ACCESS_TOKEN_ACTION = {
  set: 'set-token',
};

const AFFILIATION_ACTION = {
  set: 'set-affiliation',
};

const CUSTOMER_ACTION = {
  set: 'set-customer',
};

const DATALAYER_ACTION = {
  set: 'set-data-layer',
};

const PLAN_ACTION = {
  set: 'set-plan',
};

const QUOTATION_PLAN_ACTION = {
  set: 'set-quotation-plan',
};

const QUOTATION_ACTION = {
  set: 'set-quotation',
};

const QUOTAS_ACTION = {
  set: 'set-quotas',
};

const REQUEST_ACTION = {
  set: 'set-request',
  setId: 'set-request-id',
};

const VEHICLE_ACTION = {
  set: 'set-vehicle',
};

const ORACLE_ACTION = {
  setPartyId: 'set-party-id',
  setOptyNumber: 'set-opty-number',
  setAll: 'set-all',
};

const QUICKS_ACTION = {
  set: 'set-quick',
};

const UBIGEOS_ACTION = {
  set: 'set-ubigeos',
};

const NEW_PLAN_ACTION = {
  set: 'set-new-plan',
};

const AUTOEFECTIVO_PATH_ACTION = {
  set: 'set-autoefectivo-path',
};

const CHINESE_VEHICLE_ACTION = {
  set: 'set-chinese-vehicle',
};

const RESET_ACTION = 'reset';

enum RecaptchaAction {
  setStatus = '@set-status',
  setLoadeGtm = '@set-gtm-vendor',
}

enum CashVehiclePlanAction {
  setShowCard = '@set-show-card',
  setActiveFlow = '@set-active-flow',
  setQuoteValues = '@set-quote-values',
  reset = '@reset',
}

enum RoutingPlansAction {
  set = '@set-routing-plans',
  reset = '@reset',
}

const RECOMMENDED_PLANS_ACTION = {
  set: 'set-recommended-plans',
};

const ROUTING_PLANS_ACTION = {
  set: 'set-routing-plans',
};

const EXTERNAL_WEB_ACTION = {
  set: 'set-external-web',
};

const MIRROR_PAGE_ACTION = {
  set: 'set-mirror-page',
};

export {
  ACCESS_TOKEN_ACTION,
  AFFILIATION_ACTION,
  CUSTOMER_ACTION,
  DATALAYER_ACTION,
  PLAN_ACTION,
  QUOTATION_PLAN_ACTION,
  QUOTATION_ACTION,
  QUOTAS_ACTION,
  REQUEST_ACTION,
  VEHICLE_ACTION,
  RESET_ACTION,
  ORACLE_ACTION,
  QUICKS_ACTION,
  UBIGEOS_ACTION,
  RecaptchaAction,
  EXTERNAL_WEB_ACTION,
  NEW_PLAN_ACTION,
  MIRROR_PAGE_ACTION,
  AUTOEFECTIVO_PATH_ACTION,
  CHINESE_VEHICLE_ACTION,
  CashVehiclePlanAction,
  RECOMMENDED_PLANS_ACTION,
  ROUTING_PLANS_ACTION,
  RoutingPlansAction,
};

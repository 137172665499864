import React, { FC, memo, useMemo, useReducer } from 'react';

import { accessTokenReducer } from './reducers/access-token';
import { requestReducer } from './reducers/request';
import { vehicleReducer } from './reducers/vehicle';
import { customerReducer } from './reducers/customer';
import { quotationReducer } from './reducers/quotation';
import { planReducer } from './reducers/plan';
import { quotationPlanReducer } from './reducers/quotation-plan';
import { affiliationReducer } from './reducers/affiliation';
import { dataLayerReducer } from './reducers/data-layer';
import { oracleReducer } from './reducers/oracle';
import { RecaptchaType } from './types/recaptcha.types';
import { recaptchaReducer } from './reducers/recaptcha.reducer';
import { GlobalActionTypes } from './actions/global.action.types';
import { externalWebReducer } from './reducers/external-web';
import { quicksReducer } from './reducers/quicks';
import { quotasReducer } from './reducers/quotas';
import { ubigeosReducer } from './reducers/ubigeos';
import { newPlanReducer } from './reducers/new-plan';
import AUTOEFECTIVO_PLANS from 'domains/new-plan/constants/plans';
import { mirrorPageReducer } from './reducers/mirrror-page';
import { autoefectivoPathReducer } from './reducers/autoefectivo-path';
import { CashVehiclePlan } from 'domains/landing-autos/types/planTypes';
import { cashVehicleReducer } from './reducers/cashVehicleReducer';
import { recommendedPlansReducer } from './reducers/recommendedPlans';
import { chineseVehicleReducer } from './reducers/chinese-vehicle';
import { routingPlansReducer } from './reducers/routingPlans';

export interface IAppStore {
  accessToken: any;
  request: any;
  vehicle: any;
  customer: any;
  quotation: any;
  plan: any;
  quotationPlan: any;
  quotas: any;
  affiliation: any;
  dataLayer: {
    hasPlan: boolean;
    hasPlate: boolean;
    customerExist: boolean;
    documentType: string;
    hasAddress: boolean;
    plateExist: boolean;
  };
  quicks: any;
  ubigeos: any;
  oracle: {
    partyId: string;
    optyNumber: string;
  };
  recaptcha: RecaptchaType;
  externalWeb: any;
  autoefectivoPath: string;
  newPlan: any;
  mirrorPage: {
    active: boolean;
    name: string;
  };
  cashVehiclePlan: CashVehiclePlan;
  recommendedPlans: string[];
  vehicleEnable: boolean;
  routingPlans: string[];
}

interface IAppContext {
  store: IAppStore;
  actions: {
    dispatchAccessToken: React.Dispatch<any>;
    dispatchRequest: React.Dispatch<any>;
    dispatchVehicle: React.Dispatch<any>;
    dispatchCustomer: React.Dispatch<any>;
    dispatchQuotation: React.Dispatch<any>;
    dispatchPlan: React.Dispatch<any>;
    dispatchQuotationPlan: React.Dispatch<any>;
    dispatchQuotas: React.Dispatch<any>;
    dispatchAffiliation: React.Dispatch<any>;
    dispatchDataLayer: React.Dispatch<any>;
    dispatchOracle: React.Dispatch<any>;
    dispatchQuicks: React.Dispatch<any>;
    dispatchUbigeos: React.Dispatch<any>;
    dispatchRecaptcha: React.Dispatch<GlobalActionTypes>;
    dispatchExternalWeb: React.Dispatch<any>;
    dispatchNewPlan: React.Dispatch<any>;
    dispatchMirrorPage: React.Dispatch<any>;
    dispatchAutoefectivoPath: React.Dispatch<any>;
    dispatchCashVehiclePlan: React.Dispatch<GlobalActionTypes>;
    dispatchRecommendedPlans: React.Dispatch<any>;
    dispatchVehicleEnable: React.Dispatch<any>;
    dispatchRoutingPlans: React.Dispatch<GlobalActionTypes>;
  };
}

export const AppContext = React.createContext<IAppContext>({
  store: {} as any,
  actions: {} as any,
});

interface AppContextWrapperProps {
  children: any;
}

const AppContextWrapper: FC<AppContextWrapperProps> = ({ children }) => {
  const [accessToken, dispatchAccessToken] = useReducer(accessTokenReducer, '');
  const [request, dispatchRequest] = useReducer(requestReducer, {});
  const [vehicle, dispatchVehicle] = useReducer(vehicleReducer, {});
  const [customer, dispatchCustomer] = useReducer(customerReducer, {});
  const [quotation, dispatchQuotation] = useReducer(quotationReducer, {});
  const [plan, dispatchPlan] = useReducer(planReducer, {});
  const [mirrorPage, dispatchMirrorPage] = useReducer(mirrorPageReducer, {});
  const [newPlan, dispatchNewPlan] = useReducer(
    newPlanReducer,
    AUTOEFECTIVO_PLANS.general,
  );
  const [autoefectivoPath, dispatchAutoefectivoPath] = useReducer(
    autoefectivoPathReducer,
    AUTOEFECTIVO_PLANS.general,
  );
  const [quotationPlan, dispatchQuotationPlan] = useReducer(
    quotationPlanReducer,
    {},
  );
  const [quotas, dispatchQuotas] = useReducer(quotasReducer, []);
  const [affiliation, dispatchAffiliation] = useReducer(affiliationReducer, {});
  const [dataLayer, dispatchDataLayer] = useReducer(dataLayerReducer, {
    hasPlan: false,
    hasPlate: false,
    customerExist: false,
    documentType: 'DNI',
    hasAddress: false,
    plateExist: false,
  });
  const [oracle, dispatchOracle] = useReducer(oracleReducer, {});
  const [quicks, dispatchQuicks] = useReducer(quicksReducer, []);
  const [ubigeos, dispatchUbigeos] = useReducer(ubigeosReducer, []);
  const [recaptcha, dispatchRecaptcha] = useReducer(recaptchaReducer, {
    valid: false,
    loadedGtmVendor: false,
  });

  const [externalWeb, dispatchExternalWeb] = useReducer(
    externalWebReducer,
    null,
  );

  const [cashVehiclePlan, dispatchCashVehiclePlan] = useReducer(
    cashVehicleReducer,
    {
      showCard: false,
      activeFlow: false,
    } as CashVehiclePlan,
  );

  const [recommendedPlans, dispatchRecommendedPlans] = useReducer(
    recommendedPlansReducer,
    [],
  );

  const [vehicleEnable, dispatchVehicleEnable] = useReducer(
    chineseVehicleReducer,
    null,
  );

  const [routingPlans, dispatchRoutingPlans] = useReducer(
    routingPlansReducer,
    [],
  );

  const config = {
    store: {
      accessToken,
      request,
      vehicle,
      customer,
      quotation,
      plan,
      quotationPlan,
      quotas,
      affiliation,
      dataLayer,
      oracle,
      quicks,
      ubigeos,
      recaptcha,
      externalWeb,
      newPlan,
      mirrorPage,
      autoefectivoPath,
      cashVehiclePlan,
      recommendedPlans,
      vehicleEnable,
      routingPlans,
    },
    actions: {
      dispatchAccessToken,
      dispatchRequest,
      dispatchVehicle,
      dispatchCustomer,
      dispatchQuotation,
      dispatchPlan,
      dispatchQuotationPlan,
      dispatchQuotas,
      dispatchAffiliation,
      dispatchDataLayer,
      dispatchOracle,
      dispatchQuicks,
      dispatchUbigeos,
      dispatchRecaptcha,
      dispatchExternalWeb,
      dispatchNewPlan,
      dispatchMirrorPage,
      dispatchAutoefectivoPath,
      dispatchCashVehiclePlan,
      dispatchRecommendedPlans,
      dispatchVehicleEnable,
      dispatchRoutingPlans,
    },
  };

  const providerValue = useMemo(() => ({ config }), [config]);

  return (
    <AppContext.Provider value={providerValue.config}>
      {children}
    </AppContext.Provider>
  );
};
export default memo(AppContextWrapper);
